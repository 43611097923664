import { api } from '@/common/api';
import { dispatchCheckApiError } from '@/store/main/actions';
import { commitSetCompany, commitSetLogo, commitSetType } from './mutations';
import { getLocalToken } from '@/utils';

export const actions = {
  async actionGetCompanies(context, success) {
    try {
      context.commit('setLoading', true);
      const response = await api.getCompanies(getLocalToken());
      if(response) {
        success(context, response.data);
      }
    } catch(error) {
      await dispatchCheckApiError(context, error);
    } finally {
      context.commit('setLoading', false);
    }
  },
  async actionCreateCompany(context, payload) {
    try {
      context.commit('setLoading', true);
      const response = (await Promise.all([
        api.createCompany(getLocalToken(), payload),
        await new Promise((resolve) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitSetCompany(context, response.data);
      context.dispatch('notisSuccess', "企業情報を登録しました。");
    } catch(error) {
      await dispatchCheckApiError(context, error);
    } finally {
      context.commit('setLoading', false);
    }
  },
  async actionUpdateCompany(context, action, payload) {
    try {
      context.commit('setLoading', true);
      const response = (await Promise.all([
        api.updateCompany(getLocalToken(), payload.id, payload),
        await new Promise((resolve) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitSetCompany(context, response.data);
      if(action == "delete") {
        context.dispatch('notisSuccess', "企業情報を削除しました。");
      }
    } catch(error) {
      await dispatchCheckApiError(context, error);
    } finally {
      context.commit('setLoading', false);
    }
  },
  async actionGetLogo(context, payload) {
    try {
      context.commit('setLoading', true);
      const response = (await Promise.all([
        api.getLogo(getLocalToken(), payload.id),
        await new Promise((resolve) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitSetLogo(context, response.data.logo);
      commitSetType(context, response.data.type);
    } catch(error) {
      await dispatchCheckApiError(context, error);
    } finally {
      context.commit('setLoading', false);
    }
  }
}
export const dispatchGetCompanies = actions.actionGetCompanies;
export const dispatchCreateCompany = actions.actionCreateCompany;
export const dispatchUpdateCompany = actions.actionUpdateCompany;
export const dispatchGetLogo = actions.actionGetLogo;
